import { tw } from '@/utils/tw';
import { default as BaseVideoReelsTheme } from 'base/components/VideoReels/theme';

const VideoReels = tw.theme({
  extend: BaseVideoReelsTheme,
  slots: {
    base: 'group-[]/article:max-w-article sm:group-[]/article:max-w-article md:group-[]/article:max-w-article lg:group-[]/article:max-w-article',
    heading: 'uppercase text-headline-sm',
  },
});

export default VideoReels;
